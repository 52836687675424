<template>
  <div
    class="equipment"
    v-if="
      showId === 'fertilizer' ||
      showId === 'soil' ||
      showId === 'water' ||
      showId === 'feed' ||
      showId === 'machine' ||
      showId === 'gate' ||
      showId === 'insect' ||
      showId === 'heat' ||
      showId === 'control'
    "
  >
    <div class="eq-title">
      <span>设备状态</span>
      <span :class="[online != 1 ? 'active' : '']">
        ({{ online == 1 ? "在线" : "离线" }})
      </span>
    </div>
    <div class="eq-lists">
      <div class="eq-items" v-for="item in colDataList" :key="item.id">
        <p class="name one-txt-cut">{{ item.title }}</p>
        <p class="val" v-if="item.type != 1">
          {{ item.last_data }}{{ item.data_unit }}
        </p>

        <el-switch
          :disabled="online != 1"
          v-if="item.type === 1"
          class="my-switch"
          :class="item.last_data ? 'active' : ''"
          v-model="item.last_data"
          @change="changeSwitch($event, item.id)"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    online: {
      type: [String, Number],
      default: "",
    },
    showId: {
      type: String,
      default: "",
    },
    weatherCount: {
      type: Number,
      default: 0,
    },
    colDataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    cameraList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  methods: {
    changeSwitch(e, id) {
      this.$emit("changeSwitch", e, id);
    },
  },
};
</script>

<style lang="less" scoped>
.equipment {
  width: 282px;
  height: 55vh;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 6px solid #125397;
  padding: 23px 13px;
  background-color: #0f243d;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 166px;
  left: 78px;
  z-index: 1999;
  transition: all 0.5s;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #3e90e5;
  }

  p {
    margin: 0 0 10px 0;
    &:first-of-type {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 20px 0;
    }
  }
  .eq-title {
    color: #3e90e5;
    font-size: 16px;
    margin-bottom: 17px;
    text-align: center;
    span {
      &:last-of-type {
        font-size: 12px;
        color: #67c23a;
      }
      &.active {
        color: #f56c6c;
      }
    }
  }

  .eq-lists {
    display: flex;
    width: 100%;
    flex-direction: column;

    .eq-items {
      width: 100%;
      height: 41px;
      background: rgba(62, 144, 229, 0.25);
      border: 1px solid #3e90e5;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 6px 14px;
      justify-content: space-between;
      margin-bottom: 14px;

      .left-div {
        display: flex;
        align-items: center;
        .icon1-img {
          width: 30px;
          height: 30px;
          margin-right: 16px;
        }
      }
      .name,
      .val {
        font-size: 14px;
        color: #fff;
        font-weight: normal;
      }
      .my-switch {
        /deep/.el-switch__core {
          background-color: #d8e5f0;
        }

        /deep/.el-switch__core:after {
          background-color: #a6aeb5;
        }
      }

      .my-switch.active {
        /deep/.el-switch__core:after {
          background-color: #2878ff;
        }
      }
      p {
        margin: 0;
      }
    }
  }
}
</style>
